import classNames from 'classnames';
import { FC } from 'react';

import { InputBaseWithOptions, InputGroupValue, InputOption, InputOptionType } from '../Input.type';
import styles from './OptionFieldInput.module.scss';

type OptionFieldInput = Prettify<
  Pick<InputOption, 'indeterminate' | 'disabled'> &
    Pick<InputBaseWithOptions<InputGroupValue>, 'disabled' | 'error'> & { checked: boolean }
>;

/**
 * Visual component representing a option field (e.g. radio or checkbox)
 *
 * **Please note:** This is purely a visual representation and should be used in conjunction of a sibling input
 */
const OptionFieldInput: FC<OptionFieldInput & { type: InputOptionType }> = ({
  type,
  checked,
  disabled,
  indeterminate,
  error,
}) => (
  <div
    className={classNames(
      'fieldInput',
      styles.root,
      styles[type],
      {
        [styles.checked]: checked,
        [styles.indeterminate]: indeterminate,
        [styles.disabled]: disabled,
        [styles.error]: !!error,
      },
      type,
    )}
  >
    {type === 'switch' ? (
      <div
        className={classNames(styles.thumb, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
          [styles.error]: !!error,
        })}
      />
    ) : null}
  </div>
);

/**
 * Visual component representing a radio
 *
 * **Please note:** This is purely a visual representation and should be used in conjunction of a sibling radio input
 */
export const Radio: FC<OptionFieldInput> = (props) => <OptionFieldInput {...props} type="radio" />;

/**
 * Visual component representing a checkbox
 *
 * **Please note:** This is purely a visual representation and should be used in conjunction of a sibling checkbox input
 */
export const Checkbox: FC<OptionFieldInput> = (props) => <OptionFieldInput {...props} type="checkbox" />;

/**
 * Visual component representing a switch
 *
 * **Please note:** This is purely a visual representation and should be used in conjunction of a sibling switch input
 */
export const Switch: FC<OptionFieldInput> = (props) => <OptionFieldInput {...props} type="switch" />;
